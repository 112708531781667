@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.container {
  position: relative;
  padding-bottom: 28px;
  label {
    display: block;
    width: 100%;
    padding-bottom: 4px;
    font-size: 15px;
    line-height: 15px;
    font-family: $yonitBold;
  }
  .input-wrapper {
    position: relative;
    .form-input {
      width: 100%;
      height: 46px;
      line-height: 46px;
      padding: 0 16px;
      font-size: 14px;
      font-family: $openSans;
      color: #000000;
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      outline: none;
      &:focus,
      &.focused {
        border: 1px solid var(--primary-color);
      }
    }

    .placeholder {
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 14px;
      font-family: $openSans;
      color: #0c0c0c;
      opacity: 0.5;
      pointer-events: none;
      &.focused {
        display: none;
      }
    }
  }

  .error {
    position: absolute;
    display: block;
    width: 100%;
    right: 0;
    bottom: 12px;
    font-size: 12px;
    line-height: 12px;
    font-family: $yonitRegular;
    color: #ff1b1b;
  }
}
