@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

$black: #0c0c0c;

.sort-select {
  position: relative;
  width: 155px;
  &.show-dropdown {
    .text {
      i {
        transform: rotate(180deg);
      }
    }
    .dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 29px;
    font-size: 16px;
    font-family: $yonitRegular;
    color: $black;
    border: solid 1px #d6d6d6;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    i {
      width: 12px;
      margin-right: 10px;
      transform-origin: 50% 50%;
      transition: all ease 0.3s;
      svg {
        display: block;
        width: 100% !important;
      }
    }
  }
  .dropdown {
    position: absolute;
    right: 0;
    width: 155px;
    top: 29px;
    padding: 8px 10px;
    margin: 0;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #d6d6d6;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s;
    li {
      font-size: 16px;
      line-height: 15px;
      font-family: $yonitLight;
      color: $black;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        font-family: $yonitRegular;
      }
      &:hover {
        font-family: $yonitRegular;
      }
    }
  }
}
