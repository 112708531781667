@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.form-field-title {
  font-family: $yonitMedium;
  font-size: 1.8rem;

  @include screen('(max-width: 745px)') {
    font-size: 1.6rem;
  }

  span {
    font-family: $yonitLight;
    letter-spacing: 0.3px;
  }
}
