@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.main {
  max-width: 984px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  padding: 0 407px 0 0;

  @include screen('desktop') {
    padding: 0;
    max-width: 500px;
    margin: 0 auto 0 20%;
  }

  @include screen('(min-width: 1600px)') {
    margin: 0 auto;
  }

  @include screen('(max-width: 745px)') {
    padding: 0;
  }

  .content-wrap {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 15px 0 15px;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    @include screen('desktop') {
      padding: 0 15px 0 15px;
      justify-content: center;

      html[data-calc-type='investment'] & {
        padding: 130px 0 0 0;
        justify-content: flex-start;
      }
    }

    @include screen('(max-width: 1536px) and (min-width: 1024px) and (max-height: 725px) ') {
      justify-content: flex-start;
      padding: 70px 15px 0 15px;
    }

    @include screen('(max-width: 745px)') {
      height: auto;
      overflow: visible;
      padding: 0;
    }
  }

  .desktop-header {
    display: none;

    @include screen('desktop') {
      display: block;
    }
  }

  .title {
    display: none;
    padding: 0 0 3px 0;

    h1 {
      font-family: $yonitLight;
      font-size: 5.6rem;
      line-height: 90%;
      background: linear-gradient(247.4deg, var(--gradient-end-color) 7.16%, var(--gradient-start-color) 99.79%);
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: normal;
      padding-bottom: 15px;

      span {
        font-family: $yonitBold;
      }
    }

    p {
      font-family: $yonitRegular;
      font-size: 2.2rem;
      line-height: 100%;
      color: #0c0c0c;
    }

    @include screen('desktop') {
      display: block;
    }
  }

  .border-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 8px;
    background-color: red;
    z-index: 3;

    @include screen('desktop') {
      display: none;
    }

    @include screen('(max-width: 745px)') {
      left: 0;
      height: 6px;
      width: 100%;
    }
  }

  .decor {
    position: absolute;
    left: calc(100% - 394px);
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;

    @include screen('desktop') {
      left: initial;
      right: 0;
    }

    @include screen('(max-width: 745px)') {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
    }
  }

  .madlan-logo-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $yonitRegular;
    font-size: 1.2rem;
    line-height: 1;
    color: #151d2a;
    margin-top: 9px;

    p {
      margin-left: 5px;
    }

    @include screen('(max-width: 745px)') {
      margin-top: 0px;
      margin-bottom: 9px;
      align-self: center;
    }
  }

  .rabbi-credit {
    position: fixed;
    bottom: 10px;
    left: 20px;

    @include screen('mobile') {
      position: relative;
      text-align: center;
      margin-top: 10px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.mobile-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
