@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

@value opentransitionopacity: 300ms;

.leads-form {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 11;
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .form-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 339px;
    background: linear-gradient(263.97deg, #eff4f8 4.95%, #daeeff 97.78%);
    .top {
      position: relative;
      height: 159px;
      .background-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        svg {
          display: block;
          width: 100% !important;
          margin-top: -147px;
        }
      }

      .close-btn {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 15px;
        top: 15px;
        z-index: 3;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 2px;
          border-radius: 1px;
          background-color: #fff;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
      .text {
        position: relative;
        z-index: 2;
        .title {
          padding-top: 23px;
          padding-bottom: 8px;
          font-size: 28px;
          line-height: 25.76px;
          color: #fff;
          font-family: $yonitLight;
          text-align: center;
          span {
            font-family: $yonitBold;
          }
        }

        .sub-title {
          font-size: 18px;
          line-height: 18px;
          color: #fff;
          font-family: $yonitRegular;
          text-align: center;
        }
      }
    }

    .form {
      padding: 0 12px 18px;

      .submit-btn-warpper {
        width: 123px;
        margin: 0 auto;
      }

      .checkboxes-error {
        font-size: 12px;
        font-family: $yonitRegular;
        color: #ff1b1b;
        text-align: center;
      }

      .button {
        height: 36px;
        font-size: 16px;
        line-height: 16px;
        transition: all ease 0.3s;
        .disabled {
          pointer-events: none;
          opacity: 0.2;
        }
        &:hover {
          background-color: #ae0000;
        }
      }
    }
  }
}

.opacity-anim-enter {
  opacity: 0;
  visibility: hidden;

  &-active {
    opacity: 1;
    visibility: visible;
    transition: all opentransitionopacity ease;
  }
}

.opacity-anim-exit {
  opacity: 1;
  visibility: visible;

  &-active {
    opacity: 0;
    visibility: hidden;
    transition: all opentransitionopacity ease;
  }
}
