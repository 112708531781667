#root {
  --sticky-bottom-height: 64px;
  --sticky-bottom-height-mobile: 53px;
  --gradient-start-color: #564bce;
  --gradient-end-color: #3880e8;
  --selected-color: #271c97;
}

#root.youngCouples {
  --primary-color: #4e5ad6;
  --gradient-start-color: #564bce;
  --gradient-end-color: #3880e8;
  --selected-color: #271c97;
}

#root.luxuryApartments {
  --primary-color: #26b8b2;
  --gradient-start-color: #229da0;
  --gradient-end-color: #26bcb5;
  --selected-color: #0c6769;
}

#root.investment {
  --primary-color: #36a5ed;
  --gradient-start-color: #35a5ea;
  --gradient-end-color: #1d8ad4;
  --selected-color: #0c6769;
}

// Colors
// -------------------------------------------------------------------------------------------------
$black40: rgba(0, 0, 0, 0.4);
$black45: rgba(0, 0, 0, 0.45);
$black85: rgba(0, 0, 0, 0.85);

// Typography
// -------------------------------------------------------------------------------------------------

$yonitMedium: 'yonit', sans-serif;
$yonitBold: 'yonit-bold', sans-serif;
$yonitLight: 'yonit-light', sans-serif;
$yonitRegular: 'yonit-regular', sans-serif;
$openSans: 'Open Sans Hebrew', sans-serif;

// Media queries
// -------------------------------------------------------------------------------------------------
$media-mobile-small-max: 350;
$media-mobile-medium-max: 540;
$media-mobile-max: 767;
$media-tablet-min: 768;
$media-tablet-max: 1024;
$media-desktop-min: $media-tablet-max + 1;
$media-desktop-hd-min: 1920;
$media-desktop-small-max: 1300;

$media-mobile: '(max-width: #{$media-mobile-max}px)';
$media-mobile-medium: '(max-width: #{$media-mobile-medium-max}px)';
$media-mobile-small: '(max-width: #{$media-mobile-small-max}px)';
$media-mobile-landscape: '(max-width: #{$media-mobile-max}px) and (orientation: landscape)';
$media-mobile-portrait: '(max-width: #{$media-mobile-max}px) and (orientation: portrait)';

$media-tablet: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px)';
$media-tablet-landscape: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: landscape)';
$media-tablet-portrait: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: portrait)';

$media-desktop: '(min-width: #{$media-desktop-min}px)';
$media-desktop-hd: '(min-width: #{$media-desktop-hd-min}px)';
$media-small-desktop: '(min-width: #{$media-desktop-min}px) and (max-width: #{$media-desktop-small-max}px)';

$media-non-mobile: '(min-width: #{$media-mobile-max}px)';
$media-non-desktop: '(max-width: #{$media-tablet-max}px)';

$layout-padding-top: 16px;
$layout-header-h: 78px;
$layout-footer-h: 100px;
$layout-side-padding: 30px;
$layout-side-padding-m: 23px;

$layout-header-h-mobile: 54px;
$layout-footer-h-mobile: 50px;
