@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.city-select {
  margin-bottom: 20px;

  .field-title {
    padding-bottom: 2px;

    @include screen('(max-width: 745px)') {
      padding-bottom: 4px;
    }
  }

  .paper {
    transform: translate(0, -3px);
    border-radius: 0 0 4px 4px;
    border: 1px solid var(--gradient-start-color);
    box-shadow: none;
  }

  .popper {
    background-color: transparent;
  }

  .listbox {
    max-height: 138px;
    @include screen('(max-width: 745px)') {
      max-height: 132px;
    }
  }

  .option {
    font-family: $yonitLight;
    font-size: 1.4rem;
    color: #212121;
    padding: 12px 15px;
    @include screen('mobile') {
      padding: 7px 15px;
      min-height: 38px;
    }
  }

  .no-options {
    font-family: $yonitLight;
    font-size: 1.4rem;
    color: #212121;
  }

  .input {
    height: 46px;
    font-family: $yonitLight;
    font-size: 1.6rem;
    color: #212121;
    padding: 0 39px 0 0 !important;
    background-color: #fff;
  }
}
