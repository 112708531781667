@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.sticky-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 2;
  height: var(--sticky-bottom-height);
  bottom: 0;
  left: 0;
  background-color: #151d2a;

  @include screen('mobile') {
    height: var(--sticky-bottom-height-mobile);
  }

  .form-link {
    font-size: 30px;
    font-family: $yonitBold;
    color: #fff;
    cursor: pointer;

    @include screen('mobile') {
      font-size: 18px;
    }
  }
}
