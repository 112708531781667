@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.range-slider {
  margin-bottom: 20px;

  .field-title {
    padding: 0 0 15px 0;

    @include screen('(max-width: 745px)') {
      padding: 0 0 11px;
    }
  }

  .slider-wrap {
    padding: 0 15px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
  }

  .info-item {
    width: 130px;
    height: 46px;
    padding: 7px 11px;
    background: #ffffff;
    border: 1px solid var(--gradient-start-color);
    border-radius: 4px;
    font-family: $yonitRegular;
    font-size: 1.6rem;
    line-height: 1;

    .input {
      width: 100%;
      margin: 0;
      border: 0;
      padding: 0;
      white-space: normal;
      background: none;

      &:focus {
        outline: 0;
      }
    }

    .title {
      font-family: $yonitLight;
      font-size: 1.2rem;
      padding-bottom: 2px;
    }
  }
}
