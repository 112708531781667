.iframe-examples {
  min-height: 700px;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  iframe {
    border: none;
    box-shadow: 0px 0px 39px 5px rgba(0, 0, 0, 0.28);
  }
}
