@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.visual {
  position: relative;
  height: 100%;
  min-height: 519px;

  html[data-calc-type='investment'] & {
    min-height: 393px;
  }

  @include screen('desktop') {
    max-width: 34%;
    width: 100%;
  }

  @include screen('(max-width: 745px)') {
    height: initial;
    min-height: initial;
    width: 100%;

    html[data-calc-type='investment'] & {
      height: initial;
      min-height: initial;
      width: 100%;
    }
  }

  .content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    padding: 28px 45px 0 0;

    @include screen('desktop') {
      top: 50%;
      transform: translate(0, -50%);
      padding: 0 15% 0 0;

      html[data-calc-type='luxuryApartments'] & {
        transform: translate(0, -45%);
        padding: 0 20% 0 0;
      }

      html[data-calc-type='investment'] & {
        padding: 0 10% 0 0;
      }
    }

    @include screen('(max-width: 745px)') {
      left: 0;
      right: 0;
      padding: 0;
      padding: 25px 18px 10px 11px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .top {
      font-family: $yonitBold;
      font-size: 56px;
      line-height: 90%;
      color: #f3f3f3;
      padding: 0 0 0 107px;
      margin: 0 0 25px 0;

      @include screen('desktop') {
        display: none;
      }

      @include screen('(max-width: 745px)') {
        padding: 7px 0 0 0;
        font-size: 35px;
        line-height: 84%;
      }

      .pre-title {
        font-family: $yonitLight;
      }
    }

    .building-img {
      html[data-calc-type='luxuryApartments'] & {
        padding: 0 35px 0 0;
      }

      @include screen('desktop') {
        display: block;
        width: 84%;
        max-width: 468px;
        margin-bottom: 122px;

        html[data-calc-type='luxuryApartments'] & {
          width: 65%;
          max-width: 368px;
          padding: 0;
        }

        html[data-calc-type='investment'] & {
          width: 80%;
          max-width: 445px;
          padding: 0;
        }
      }

      @include screen('(max-width: 745px)') {
        display: block;
        width: 110px;

        html[data-calc-type='luxuryApartments'] & {
          width: 72px;
          padding: 0;
          margin: -10px 0 0 42px;
        }

        html[data-calc-type='investment'] & {
          width: 112px;
          margin: -13px 0 0 19px;
        }
      }
    }
  }

  svg {
    display: block;
    height: 100%;
    width: auto;

    @include screen('desktop') {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    @include screen('(max-width: 745px)') {
      width: 100vw;
      height: initial;
    }
  }
}
