@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.top-section {
  position: relative;
  margin-top: 56px;
  height: 325px;
  @include screen('mobile') {
    height: 202px;
  }
  .wrapper {
    position: absolute;
    width: 100%;
    max-width: 1491px;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);

    svg {
      display: block;
      width: 100% !important;
      margin-top: -100px;
    }

    .back-button {
      position: absolute;
      left: 200px;
      top: 3px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: $yonitRegular;
      font-weight: 500;
      color: #fff;
      cursor: pointer;
      @include screen('mobile') {
        left: 4.3%;
        top: 1px;
        font-size: 16px;
      }
      i {
        margin-right: 10px;
        @include screen('mobile') {
          margin-right: 8px;
        }
        svg {
          display: block;
          width: 24px !important;
          margin-top: 0;
          @include screen('mobile') {
            width: 20px !important;
          }
        }
      }
    }

    .info {
      position: absolute;
      width: 100%;

      &.with-city {
        top: 44px;
        @include screen('mobile') {
          top: 52px;
        }
        .title {
          font-size: 80px;
          line-height: 73.6px;
          text-align: center;
          font-family: $yonitLight;
          color: #fff;
          @include screen('mobile') {
            font-size: 45px;
            line-height: 37.35px;
          }
        }
        .city-name {
          padding-bottom: 15px;
          font-size: 80px;
          line-height: 73.6px;
          text-align: center;
          font-family: $yonitBold;
          color: #fff;
          @include screen('mobile') {
            font-size: 45px;
            line-height: 37.35px;
          }
        }
      }

      &.without-city {
        top: 44px;
        @include screen('mobile') {
          top: 52px;
        }
        .title {
          font-size: 80px;
          line-height: 73.6px;
          text-align: center;
          font-family: $yonitLight;
          color: #fff;
          @include screen('mobile') {
            font-size: 45px;
            line-height: 37.35px;
          }
        }
        .sub-title {
          padding-bottom: 15px;
          font-size: 80px;
          line-height: 73.6px;
          text-align: center;
          font-family: $yonitBold;
          color: #fff;
          @include screen('mobile') {
            font-size: 45px;
            line-height: 37.35px;
          }
        }
      }
      &.no-results {
        top: 30px;
        .icon-wrapper {
          width: 99px;
          margin: 0 auto 13px;
          @include screen('mobile') {
            width: 63px;
          }
          i {
            display: block;
            width: 100% !important;
            svg {
              display: block;
              width: 100% !important;
              height: auto !important;
              margin-top: 0;
            }
          }
        }
        .title {
          padding-bottom: 13px;
          font-size: 45px;
          line-height: 41.4px;
          text-align: center;
          font-family: $yonitMedium;
          color: #fff;
          @include screen('mobile') {
            padding: 0 0 4px;
            font-size: 30px;
            line-height: 27px;
          }
        }
      }
      .client-more-info {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: $yonitLight;
        font-size: 18px;

        img {
          width: 74px;
          margin-right: 7px;
        }
      }
    }
  }
}
