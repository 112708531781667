@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.checkbox {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 11.5px;
  label {
    position: relative;
    width: 17px;
    height: 17px;
    margin-top: -3px;
    border: solid 1px var(--primary-color);
    background-color: transparent;
    border-radius: 2px;
    flex-shrink: 0;
    cursor: pointer;

    &.active {
      &:after {
        display: block;
      }
    }
    input {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 9px;
      height: 5px;
      margin-top: -1px;
      border: solid 2px var(--primary-color);
      border-right: none;
      border-top: none;
      z-index: 2;
      display: none;
    }
  }

  .confirmation {
    padding-right: 7.5px;
    padding-left: 31px;
    font-size: 14px;
    line-height: 14px;
    font-family: $yonitRegular;
    color: #000;
    white-space: normal;
    a {
      color: #000;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
