@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.realty-select {
  margin-bottom: 24px;

  .items {
    display: flex;
    flex-flow: row wrap;
    column-gap: 8px;
    row-gap: 12px;
  }

  .field-title {
    padding-bottom: 3px;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    color: #fff;
    width: 111px;
    height: 32px;
    background: linear-gradient(94.46deg, var(--gradient-start-color) -19.22%, var(--gradient-end-color) 107.74%);
    border-radius: 4px;
    font-family: $yonitRegular;
    font-size: 1.4rem;
    white-space: nowrap;

    @include screen('(max-width: 865px)') {
      width: 100px;
    }

    @include screen('(max-width: 745px)') {
      width: 31.7%;
    }

    &.selected {
      font-family: $yonitBold;
      font-size: 1.4rem;
      background: var(--selected-color);
    }
  }
}
