@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.rabbi-credit {
  position: absolute;
  font-family: $yonitRegular;
  font-size: 1.2rem;
  color: rgb(135, 142, 148);

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
