@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.results {
  position: relative;
  padding-bottom: 30px;

  @include screen('mobile') {
    padding-bottom: 40px;
  }

  .no-results-title {
    padding: 30px 0 16px;
    font-size: 35px;
    line-height: 32.2px;
    text-align: center;
    color: var(--primary-color);
    font-family: $yonitRegular;
    @include screen('mobile') {
      padding: 25px 0 10px;
      font-size: 25px;
      line-height: 23px;
    }
  }

  .apartments-grid {
    width: 100%;
    max-width: 1112px;
    padding: 16px 10px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include screen('mobile') {
      padding: 15px 16px 0;
    }
  }

  .more-btn-wrapper {
    width: 470px;
    margin: 0 auto 123px;
    @include screen('mobile') {
      width: 100%;
      padding: 0 16px;
      margin: 0 auto 79px;
    }

    button {
      transition: all ease 0.3s;
      &:hover {
        background-color: #ae0000;
      }
    }
  }

  .sort-select-wrapper {
    position: relative;
    width: 100%;
    max-width: 1112px;
    padding: 16px 10px 16px;
    margin: 0 auto;
    z-index: 2;
    @include screen('mobile') {
      padding: 15px 16px 0;
    }
  }
}

.rabbi-credit {
  bottom: calc(var(--sticky-bottom-height) + 15px);
  left: 20px;

  @include screen('mobile') {
    position: relative;
    text-align: center;
    bottom: calc(var(--sticky-bottom-height-mobile) + 15px);
    left: 50%;
    transform: translate(-50%, 0);
  }
}
