@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'yonit';
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Medium.eot');
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Medium.woff') format('woff'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'yonit-bold';
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Bold.eot');
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Bold.woff') format('woff'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'yonit-light';
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Light.eot');
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Light.eot?#iefix') format('embedded-opentype'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Light.woff') format('woff'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'yonit-regular';
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Regular.eot');
  src: url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Regular.woff') format('woff'),
    url('https://rcs.mako.co.il/precache/fonts/yonit/Yonit-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
