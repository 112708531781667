@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.apartment-item {
  position: relative;
  width: 32%;
  padding-bottom: 48.5px;
  margin-bottom: 41px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;

  @include screen('mobile') {
    width: 100%;
    margin-bottom: 16px;
  }

  &.with-hover {
    cursor: pointer;
  }

  .photo {
    width: 100%;
    height: 191px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .apartment-info {
    padding: 18.5px 24px 14px;
    &.investment {
      .row {
        .name {
          font-size: 26px;
          line-height: 24px;
          font-family: $yonitMedium;
          color: var(--primary-color);
        }
        .row-title {
          min-width: 112px;
        }
      }
    }
    .row {
      display: flex;
      align-items: flex-start;
      &:first-child {
        justify-content: space-between;
        padding-bottom: 16px;
      }
      .price {
        font-size: 26px;
        line-height: 24px;
        font-family: $yonitMedium;
        color: var(--primary-color);
      }
      .city-name {
        height: 25px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-size: 14px;
        color: #0c0c0c;
        font-family: $yonitRegular;
        background-color: #eff4f8;
        border-radius: 13px;
      }
      .row-title {
        min-width: 78px;
        font-size: 14px;
        line-height: 20px;
        font-family: $openSans;
        color: #000;
      }
      .info {
        flex-grow: 1;
        font-size: 14px;
        line-height: 20px;
        font-family: $openSans;
        font-weight: 600;
        color: #0c0c0c;
      }
    }
  }

  .button-wrapper {
    position: absolute;
    width: 100%;
    padding: 0 26px 0;
    bottom: 16.5px;
    left: 50%;
    transform: translate(-50%, 0);

    button {
      display: flex;
      width: 100%;
      height: 32px;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: $yonitBold;
      background: linear-gradient(94.46deg, var(--gradient-start-color) -19.22%, var(--gradient-end-color) 107.74%);
      color: #fff;
    }
  }
}
