@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.search-form {
  padding: 15px 0 0 107px;
  width: 100%;

  @include screen('desktop') {
    padding: 15px 0 0 0;
  }

  @include screen('(max-width: 865px)') {
    padding: 15px 0 0 20px;
  }

  @include screen('(max-width: 745px)') {
    padding: 0 16px 0 16px;
    position: relative;
    top: -11px;
  }

  .submit-button {
    transition: all ease 0.3s;

    &:hover {
      background-color: #ae0000;
    }
  }

  .submit-button-a {
    cursor: pointer;
    background-color: #db0000;
    height: 44px;
    font-family: $yonitBold;
    font-size: 2rem;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #db0000;
    border-radius: 8px;
    color: #ffffff;
    transition: all ease 0.3s;

    &:hover {
      background-color: #ae0000;
    }

    @include screen('(max-width: 745px)') {
      font-size: 1.6rem;
      height: 40px;
    }

    &.full-width {
      width: 100%;
    }

    &.without-corners {
      border-radius: 0px;
    }

    i {
      &.left-quotes {
        width: 14px;
        margin-right: 13px;
      }

      svg {
        display: block;
        width: 100% !important;
      }
    }
  }
}
