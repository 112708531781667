@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
.app {
  position: relative;
  min-height: 100vh;

  @include screen('mobile') {
    min-height: inherit;
  }
}
